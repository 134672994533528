import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { withDefaultLayout } from 'layouts/with-default-layout'
import { greaterThan } from 'utils/styles'
import { replaceNToBr } from 'utils/replace-n-to-br'
import { Container } from 'components'

const AboutMeTile = styled.div`
  background: #232323;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;


  &:before {
    content: '';
    background-image: url(${({ image }) => image});
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;

    ${greaterThan.medium`
      background-position: center center;
      background-attachment: fixed;
    `}
  }
`

const AboutMeTileContainer = styled(Container)`
  height: 100%;
  position: relative;

  display: flex;
  justify-content: center;
  flex-direction: column;
`

const HeadingsContainer = styled.div`

`

const FirstHeading = styled.h1`
  color: #D0D0D0;
  font-size: 36px;
  margin: 0;
  font-weight: 400;
`

const SecondHeading = styled.h2`
  color: #fff;
  font-size: 36px;
  margin: 5px 0 0;
  font-weight: 400;
`

const ContentContainer = styled.div`
  margin: 104px 0 40px;

  ${greaterThan.medium`
    margin: 0;
    width: 45%;
    align-self: flex-end;
  `}
`

const Paragraph = styled.p`
  font-family: 'Gotham';
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  margin: 40px 0 0 0;

  &:first-child {
    margin-top: 0;
  }
`

const ClientsTile = styled.div`
  position: relative;
  padding: 200px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const ClientsTileContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${greaterThan.medium`
    flex-direction: row;
    justify-content: flex-end;
  `}
`

const ClientsHeadingsContainer = styled.div`
  ${greaterThan.medium`
    margin-right: 100px;
  `}
`

const ClientsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  ${greaterThan.medium`
    width: 45%;
    margin-top: 0;
  `}
`

const Clients = styled.p`
  font-size: 15px;
  color: #fff;
  margin: 0;
  line-height: 32px;
  font-family: 'Gotham';
  font-weight: 400;
`

const PartnersTile = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PartnerSlab = styled.a`
  flex: 1 0 100%;
  position: relative;
  height: 720px;
  text-decoration: none;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    background-image: url(${({ image }) => image});
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center center;
  }

  &:first-child:before {
    background-position: 40% center;
  }

  ${greaterThan.medium`
    flex: 1  0 ${({ half }) => half ? '50%' : '100%'};

    &:before {
      transform: scale(1.1);
      transition: transform 0.3s ease-in-out;
    }

    &:hover:before {
      transform: scale(1);
    }

    &:first-child {
      background-position: center center;
    }
  `}
`

const PartnerSlabHeadingsContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 95px;

  ${greaterThan.medium`
    left: 95px;
  `}

  & > ${FirstHeading},
  & > ${SecondHeading} {
    text-shadow: 2px 2px 5px rgba(0,0,0, 0.2);
  }
`

const About = ({ data: { about, partners: { partners, image } } }) => (
  <>
    <AboutMeTile image={image.file.url}>
      <AboutMeTileContainer>

        <ContentContainer>
          <HeadingsContainer>
            <FirstHeading>{about.firstHeading}</FirstHeading>
            <SecondHeading>{about.secondHeading}</SecondHeading>
          </HeadingsContainer>

          <Paragraph dangerouslySetInnerHTML={{__html: replaceNToBr(about.description.description) }} />
        </ContentContainer>
      </AboutMeTileContainer>
    </AboutMeTile>

    <ClientsTile>
      <ClientsTileContainer>
        <ClientsHeadingsContainer>
            <FirstHeading>{about.clientsFirstHeading}</FirstHeading>
        </ClientsHeadingsContainer>

        <ClientsContainer>
          <Clients>{about.clients.map(({ name }) => name).join(', ')}</Clients>
        </ClientsContainer>
      </ClientsTileContainer>
    </ClientsTile>
    <PartnersTile>
      {about.partners.map(({ firstHeading, secondHeading, hoverLink }, index) => (
        <PartnerSlab href={hoverLink} target="_blank" key={index} half={index !== 0} image={partners[index].image.file.url}>
          <PartnerSlabHeadingsContainer>
            <FirstHeading>{firstHeading}</FirstHeading>
            <SecondHeading>{secondHeading}</SecondHeading>
          </PartnerSlabHeadingsContainer>
        </PartnerSlab>
      ))}
    </PartnersTile>
  </>
)

export default withDefaultLayout(About)

export const query = graphql`
  query ($language: String!) {
    about: contentfulAboutPage(node_locale: { eq: $language }) {
      firstHeading
      secondHeading
      description {
        description
      }
      clientsFirstHeading
      clients {
        name
      }
      partners {
        firstHeading
        secondHeading
        hoverLink
      }
    }
    partners: contentfulAboutPage {
      image {
        file {
          url
        }
      }
      partners {
        image {
          file {
            url
          }
        }
      }
    }
    footer: contentfulFooter(node_locale: { eq: $language }) {
      heading
      firstAddress {
        firstAddress
      }
      secondAddress {
        secondAddress
      }
      writeToMe
      instagramUrl
      facebookUrl
    }
  }
`
